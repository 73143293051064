import { UserSummaryModelDto } from '@treasury/api/bo';
import { clone } from '@treasury/utils';
import { DomainEntity } from '../../../../shared';

const nullCompanyUser: UserSummaryModelDto = {
    id: 0,
    userId: '',
    status: '',
    isLocked: false,
    isAdmin: false,
    isSuperUser: false,
    isActive: false,
    isEditor: false,
    isMfaLocked: false,
    authenticationMethodId: 0,
};

enum PublishState {
    Pending = 'Pending',
    FiPending = 'FiPending',
}

enum Status {
    Enrolled = 'Enrolled',
}

export class CompanyUserSummary extends DomainEntity<UserSummaryModelDto> {
    constructor(dto = clone(nullCompanyUser)) {
        super(dto);
    }

    public createDefault(): this {
        return new CompanyUserSummary(clone(nullCompanyUser)) as this;
    }

    public get id() {
        return this.dto.id;
    }

    public get userId() {
        return this.dto.userId;
    }

    public get digitalId() {
        return this.dto.digitalId;
    }

    public get userName() {
        return this.dto.userName;
    }

    public get enrolledStatus() {
        return this.dto.status;
    }

    public get isActive() {
        return this.dto.isActive;
    }

    public get isEnrolled() {
        return this.dto.status === Status.Enrolled;
    }

    public get canApproveReject() {
        return this.dto.publishState === PublishState.Pending && !this.dto.isEditor;
    }

    public get canEdit() {
        return (
            this.dto.publishState !== PublishState.Pending &&
            this.dto.publishState !== PublishState.FiPending
        );
    }

    public get canCopy() {
        return this.dto.isActive && this.dto.status === Status.Enrolled;
    }

    public get hasDigitalId() {
        return !!this.digitalId?.trim();
    }

    public set isActive(value: boolean) {
        this.dto.isActive = value;
    }

    /**
     * Determines if the UIS enrollment link can be resent to the user.
     *
     * @returns {boolean} - True if the user is active, enrolled, and does not have a digital ID; otherwise, false.
     */
    public get canResendUisEnrollmentLink() {
        return this.isActive && this.isEnrolled && !this.hasDigitalId;
    }

    public get isSuperUser() {
        return this.dto.isSuperUser;
    }

    public get isAdmin() {
        return this.dto.isAdmin;
    }

    public get isLocked() {
        return this.dto.isLocked;
    }
}
