export enum FeatureApiType {
    ACH = 'ACH',
    AccountRecon = 'FiAccountReconProductFeature',
    Agiletics = 'AgileticsSso',
    BillPay = 'BillPayProductFeature',
    ElectronicDocuments = 'ElectronicDocuments',
    ForeignCurrencyWire = 'WireTransferDliProductFeature',
    PositivePay = 'ArpProductFeature',
    RemoteDeposit = 'RemoteDepositCaptureSsoProductFeature',
    Reporting = 'InformationReportingProductFeature',
    StopPayment = 'StopPayment',
    Transfer = 'InternalTransferProduct',
    FiTransfer = 'FiInternalTransferProductFeature',
    Wausau = 'WausauStatements',
    Wire = 'WireTransferProductFeature',
}
