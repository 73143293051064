/* eslint-disable no-use-before-define */
/* eslint-disable prefer-destructuring */
import { Recordset } from '@treasury/FDL';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { TmApiError } from '@treasury/domain/shared';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/layouts/omega-report';
import { printNode } from '@treasury/utils';
import { LitElement, html, nothing, render } from 'lit';
import { mix } from 'mixwith';
import '../../components/blocking-loader.js';
import BackOfficeAlertMixin from '../../mix-ins/back-office-alert-mixin.js';
import UserActivityReportClient from '../clients/user-activity-report-client.js';
import {
    activityColumns,
    activityFields,
    activityFilters,
    isChannelActivityReport,
} from '../data/report-user-activity-fields.js';
import '../parts/report-summary.js';
import { reportContainerStyles } from './styles.js';

class ReportUserActivityContainer extends mix(LitElement).with(
    BackOfficeAlertMixin,
    ListeningElementMixin
) {
    static get properties() {
        return {
            reportType: String,
            reportId: String,
            reportMetaData: Object,
            reportRecordset: Object,
            reportSummaryRecord: Object,
            actions: Object,
            alert: Object,
            columns: Array,
            filters: Array,
            fields: Object,
            subTitle: String,
            client: Object,
            loading: Boolean,
            saving: Boolean,
            downloading: Boolean,
            printing: Boolean,
            isChannelActivityReport: Boolean,
        };
    }

    constructor() {
        super();
        this.actions = {};
        this.title = '';
        this.subTitle = '';
        this.filters = [];
        this.fields = {};
        this.columns = [];
        this.options = ['save', 'download', 'print'];
        this.downloadOptions = ['CSV', 'PDF'];
        this.rowsPerPage = 25;
        this.client = new UserActivityReportClient();
        this.isChannelActivityReport = false;
    }

    get pageTitle() {
        if (!this.reportType) return '';
        if (Object.keys(this.customFilterValues).length) return this.customFilterValues.name;
        return this.reportType.toLowerCase().includes('channel')
            ? 'Customer User Activity Report'
            : 'FI User Activity Report';
    }

    get reportTypeParameter() {
        return this.isChannelActivityReport
            ? 'boChannelUserActivity'
            : 'boFiUserActivity';
    }

    get autostart() {
        return Object.keys(this.customFilterValues)?.length > 0;
    }

    async firstUpdated() {
        const isUisEnabled = await FeatureFlagService.isEnabled(Feature.UisEnabled);
        const urlPath = window.location.pathname.split('/');
        this.reportType = urlPath[2].split('-').pop();
        this.isChannelActivityReport = isChannelActivityReport(this.reportType);
        this.reportId = urlPath[3];
        this.loading = true;
        this.fields = activityFields(this.reportType);
        this.columns = activityColumns(this.reportType, isUisEnabled);
        this.customFilterValues =
            (await this.client.getCustomReportFilters(this.reportTypeParameter, this.reportId)) ??
            {};

        this.filters = activityFilters(this.reportType, this.customFilterValues);
        try {
            this.isChannelActivityReport ?
            this.reportRecordset = new Recordset(this.fields, async (...args) =>
                this.client.runChannelUserActivityReport(this.isChannelActivityReport, ...args)
            )
            : this.reportRecordset = new Recordset(this.fields, async (...args) =>
                this.client.runBackOfficeUserActivityReport(this.isChannelActivityReport, ...args)
            )

            this.reportRecordset.setInitialPageSize(this.rowsPerPage);
            this.listenTo(this.reportRecordset, 'error', e => this.handleRecordsetError(e));
        } catch (e) {
            console.log(e);
            const { message } = coerceError(e);
            this.alert = { ...this.alert, visible: true, message, type: 'error' };
        } finally {
            this.loading = false;
        }
    }

    clearSubtitle() {
        this.subTitle = '';
    }

    handleRecordsetError({ detail }) {
        const { error } = detail;
        this.alert = {
            ...this.alert,
            visible: true,
            type: 'error',
            message: error.message,
            time: error.time,
            code: error.code,
        };
    }

    async saveReport({ detail }) {
        this.saving = true;
        try {
            await this.client.saveReport(
                detail,
                this.reportRecordset,
                this.reportTypeParameter,
                this.reportId
            );
            this.alert = {
                ...this.alert,
                visible: true,
                code: null,
                time: null,
                type: 'success',
                message: 'Report saved successfully!',
            };
            this.shadowRoot
                .querySelector('#report')
                .shadowRoot.querySelector('#report-download-bar').saveReportModal = false;
        } catch (e) {
            const { message, code, time } = coerceError(e);
            this.alert = {
                ...this.alert,
                visible: true,
                type: 'error',
                message,
                code,
                time,
            };
        } finally {
            this.saving = false;
        }
    }

    async printReport() {
        this.printing = true;
        const printRecordset = new Recordset(this.fields, () => this.reportRecordset.getData());
        await printRecordset.requestUpdate();
        printRecordset.setInitialPageSize(printRecordset.filteredCount);
        this.printing = false;
        const printableDiv = document.createElement('div');
        const printableTable = html`<omega-table
            .recordset=${printRecordset}
            .columnDefinitions=${this.columns}
        ></omega-table>`;
        render(printableTable, printableDiv);
        return printNode(this.pageTitle, printableDiv);
    }

    findDownloadTypeId(type) {
        if (this.isChannelActivityReport) {
            return type === 'csv' ? 'ChannelUserActivityCSV' : 'ChannelUserActivityPdf';
        }
        return type === 'csv' ? 'BoFiUserActivityCsv' : 'BoFiUserActivityPdf';
    }

    async downloadReport({ detail }) {
        this.downloading = true;
        const downloadTypeId = this.findDownloadTypeId(detail.type.toLowerCase());
        try {
            await this.client.downloadUserActivityReport(this.isChannelActivityReport, detail, downloadTypeId, this.reportRecordset)
        } catch (e) {
            const { message, code } = coerceError(e);
            this.alert = {
                ...this.alert,
                visible: true,
                type: 'error',
                message,
                code,
            };
        } finally {
            this.downloading = false;
        }
    }

    renderBlockingLoader() {
        if (this.loading || this.saving || this.downloading || this.printing)
            return html`<blocking-loader></blocking-loader>`;
        return nothing;
    }

    renderSummary() {
        if (!this.reportSummaryRecord) return nothing;
        return html`<report-summary
            .summaryRecord=${this.reportSummaryRecord}
            .summary=${this.reportRecordset.summary}
        ></report-summary>`;
    }

    renderReport() {
        if (this.loading) return nothing;
        if (this.reportRecordset && !this.loading) {
            return html`<omega-report
                id="report"
                .autostart=${this.autostart}
                .title=${this.pageTitle}
                .subTitle=${this.subTitle}
                .actions=${this.actions}
                .recordset=${this.reportRecordset}
                .filters=${this.filters}
                .columns=${this.columns}
                .options=${this.options}
                .downloadOptions=${this.downloadOptions}
                .rowsPerPage=${this.rowsPerPage}
                .printFunction=${() => this.printReport()}
                @resetFilter=${this.clearSubtitle}
                @reportSave=${this.saveReport}
                @reportDownload=${this.downloadReport}
            >
                <div slot="above-table">${this.renderSummary()}</div>
            </omega-report>`;
        }
        return nothing;
    }

    render() {
        return [this.renderBlockingLoader(), this.renderAlert(), this.renderReport()];
    }

    // eslint-disable-next-line @treasury/style-includes-host-display
    static get styles() {
        return [reportContainerStyles];
    }
}

customElements.define('report-user-activity-container', ReportUserActivityContainer);

/**
 * Coerce an error response to a shape usable by `<omega-alert>`.
 * @param { Error | TmError | object} e
 */
function coerceError(e) {
    const message = e instanceof Error ? e.message : 'An unknown error occurred.';
    const code = e instanceof TmApiError ? e.errorCode : undefined;
    const time = e instanceof TmApiError ? e.timestamp : undefined;

    return {
        message,
        code,
        time,
    };
}
