import { DateFormatters } from '../../../shared/utilities/date-formatters.js';
import camelToUpperCase from '../../../utilities/camel-to-upper-case.js';

const DATE_PERIODS = {
    today: 'Today',
    specific: 'Specific Date',
    range: 'Date Range',
    'week-to-date': 'Week To Date',
    'month-to-date': 'Month To Date',
    'year-to-date': 'Year To Date',
};

export const getSortableColumn = sort =>
    sort.length
        ? [{ name: camelToUpperCase(sort[0].field), descending: sort[0].sort === 'descending' }]
        : [
              {
                  name: 'ActivityDate',
                  descending: true,
              },
          ];

export const getTime = (isSpecificDate, time, dateValue) => {
    if (isSpecificDate && time && dateValue) {
        return `${dateValue} ${time.formattedTime}`;
    }
    return null;
};

export const getSpecificDate = datesValue => {
    if (datesValue.start) return null;
    if (datesValue === 'today') return DateFormatters.removeTime(DateFormatters.getToday());
    return datesValue;
};

export const getDatePeriod = dates =>
    dates.value.start ? DATE_PERIODS.range : DATE_PERIODS.specific;

export const getDatePeriodValue = (period, dates) =>
    DATE_PERIODS[period.id] ?? getDatePeriod(dates);

export const getReportType = type =>
    type?.toLowerCase()?.includes('channel') ? 'boChannelUserActivity' : 'boFiUserActivity';

const mapDateAndTimeParameters = parameters => {
    // wrap if coming as an array from a custom report so our other mappers work
    if (Array.isArray(parameters.activityPeriodDate)) {
        // eslint-disable-next-line no-param-reassign
        parameters.activityPeriodDate = { dates: parameters.activityPeriodDate };
    }
    const dates = DateFormatters.parseDate(parameters.activityPeriodDate);
    const isSpecificDate = !DateFormatters.isDateRange(parameters.activityPeriodDate);
    return {
        dateType: getDatePeriodValue(parameters.activityPeriodDate, dates),
        specificDate: getSpecificDate(dates.value),
        fromDate: dates.value.start ?? getSpecificDate(dates.value),
        fromTime: getTime(isSpecificDate, parameters.startTime, dates.value), // to and from times match dates when range
        toDate: dates.value.end ?? getSpecificDate(dates.value),
        toTime: getTime(isSpecificDate, parameters.endTime, dates.value), // times only available when specific date or today
    };
};

export const mapUserActivityRequest = (isChannelActivityReport, { page, pageSize, sort, startIndex, parameters }) => {
    const mappedParameters = {
        ...parameters,
        ...mapDateAndTimeParameters(parameters),
        pageNumber: page,
        pageSize,
        orderBys: getSortableColumn(sort),
        startIndex,
        name: isChannelActivityReport ? 'Channel User Activity Report' : 'User Activity Report',
        reportType: isChannelActivityReport ? 'boChannelUserActivity' : 'boFiUserActivity',
        ...(!isChannelActivityReport && { reportId: 0 }),
    };

    delete mappedParameters.activityPeriodDate;
    return mappedParameters;
};

export const mapReportToCustomReportRequest = ({ filter, name }, recordset, type) => {
    const mappedParameters = {
        ...filter,
        ...mapDateAndTimeParameters(filter),
    };
    delete mappedParameters.activityPeriodDate;
    return {
        ...mappedParameters,
        name,
        orderBys: getSortableColumn(recordset.sortColumns),
        pageNumber: recordset.pageNumber,
        pageSize: recordset.pageSize,
        reportType: getReportType(type),
        reportId: 0,
    };
};
