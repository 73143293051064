import {
    mapReportToCustomReportRequest,
    mapUserActivityRequest,
} from '../../mappings/reports/map-activity-reports-to-request.js';
import { UserActivityReportRequests } from '../../requests/reports/user-activity-reports.js';

export default class UserActivityReportsService {
    static async getCompanies() {
        return UserActivityReportRequests.fetchCompanies();
    }

    static async getCompanyUsers(id) {
        return UserActivityReportRequests.fetchCompanyUsers(id);
    }

    static async getActivityTypes() {
        return UserActivityReportRequests.fetchActivityTypes();
    }

    static async getAllUsers() {
        return UserActivityReportRequests.fetchAllBackOfficeUsers();
    }

    static async getChannelUserActivity(...args) {
        const body = mapUserActivityRequest(...args);
        return UserActivityReportRequests.fetchChannelUserActivity(body);
    }

    static async getBackOfficeUserActivity(...args) {
        const body = mapUserActivityRequest(...args);
        return UserActivityReportRequests.fetchBackOfficeUserActivity(body);
    }

    static async downloadUserActivityReport(isChannelActivityReport, params, downloadType, recordset) {
        const filters = mapUserActivityRequest(
            isChannelActivityReport,
            {page: recordset.pageNumber,
            pageSize: recordset.pageSize,
            sort: recordset.sortColumns,
            startIndex: recordset.pageIndex,
            parameters: params.filter,
            type: downloadType,
        });
        const requestParameters = {
            filter: {
                reportFilter: {
                    ...filters,
                },
            },
            downloadType,
        };
        return UserActivityReportRequests.downloadReport(requestParameters, downloadType);
    }

    static async saveReport(params, recordset, type, id) {
        const requestParameters = mapReportToCustomReportRequest(params, recordset, type, id);
        return UserActivityReportRequests.saveReport(requestParameters);
    }
}
