import { FiProductFeatureModelDto, FiTransferProductFeatureModelDto } from '@treasury/api/bo';
import { clone } from '@treasury/utils';
import { DomainEntity } from '../../../../shared';
import { SpecificCompanyProductFeature } from '../types';
import { FeatureApiType } from '../types/product-feature.enum';
import { TransferProductFeature } from './transfer-product-feature.entity';

const nullFiProductFeature: FiProductFeatureModelDto = {
    id: 0,
    updatedDate: new Date().toDateString(),
    name: '',
    status: '',
    type: '',
    isCompanyProductConfigurable: false,
};

export class FiProductFeature extends DomainEntity<FiProductFeatureModelDto> {
    constructor(dto = clone(nullFiProductFeature)) {
        super(dto);
    }

    public createDefault(): this {
        return new FiProductFeature(clone(nullFiProductFeature)) as this;
    }

    public get id() {
        return this.dto.id;
    }

    public get status() {
        return this.dto.status;
    }

    public get isCompanyProductConfigurable() {
        return this.dto.isCompanyProductConfigurable;
    }

    public get name() {
        return this.dto.name;
    }

    public get type() {
        return this.dto.type as FeatureApiType;
    }

    public toCompanyProductFeature(
        featureType: FeatureApiType
    ): SpecificCompanyProductFeature | undefined {
        if (featureType === FeatureApiType.FiTransfer) {
            const transferDto = this.dto as FiTransferProductFeatureModelDto;
            return {
                id: 0,
                accountPermissions: transferDto.accountPermissions,
                dailyLimit: transferDto.dailyLimit ?? 0,
                approval: transferDto.approval ?? 'None',
                templateApproval: transferDto.templateApproval ?? 'None',
                name: this.name,
                status: this.status,
                type: FeatureApiType.Transfer,
                isConfigurable: this.isCompanyProductConfigurable,
            } as TransferProductFeature;
        }
    }
}
