import { Injectable } from '@jack-henry/frontend-utils/di';
import { exists } from '@jack-henry/frontend-utils/functions';
import { SessionStorageService } from '@treasury/utils';
import { TmHttpClient } from '../http';
import { LoginResponseDto, XperienceContextConfig } from './experience.types';

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        jackHenry: any;
    }
}

@Injectable()
export class XperienceService {
    constructor(
        private readonly http: TmHttpClient,
        private readonly sessionStorageService: SessionStorageService
    ) {}

    public get isXperienceFrameworkHosted() {
        return this.sessionStorageService.get('xperienceHosted');
    }

    public async login(): Promise<LoginResponseDto> {
        const userContext = await this.getXperienceUserContext();
        const loginResponse = await this.samlLogin(userContext.UsrSecTokenRec.samlAssertion);
        return loginResponse.success ? Promise.resolve(loginResponse) : Promise.reject();
    }

    private samlLogin(samlToken: string) {
        return this.http.request<LoginResponseDto>(`Xperience/SamlLogin`, {
            method: 'POST',
            body: { samlToken },
        });
    }

    private async getXperienceUserContext() {
        await this.connectToMessageBus();
        const { jackHenry } = window;
        const currentUserRequest = new jackHenry.enterprise.businessObjects.UsrSecTokenRetrv();
        currentUserRequest.RefreshFromServer = 'false';
        if (exists(currentUserRequest.JESMsgRqHdr)) {
            const header = new jackHenry.enterprise.businessObjects.JESMsgRqHdr_CType();
            header.JESHdr = new jackHenry.enterprise.businessObjects.JESHdr_CType();
            header.JESHdr.ConsumerProd = 'TreasuryBackoffice';
            currentUserRequest.JESMsgRqHdr = header;
        }
        return new Promise<XperienceContextConfig>(resolve => {
            jackHenry.xperience.messageBus
                .send(currentUserRequest)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .registerResponseHandler(async (context: any) => {
                    if (context.isValid()) {
                        const response = context.messageToJson();
                        resolve(response);
                    }
                });
        });
    }

    private connectToMessageBus() {
        const { jackHenry } = window;
        return new Promise<void>((resolve, reject) => {
            setTimeout(() => {
                jackHenry.xperience.messageBus.onReady(() => {
                    if (jackHenry.xperience.isFrameworkHosted) {
                        this.sessionStorageService.set('xperienceHosted', true);
                        jackHenry.xperience.messageBus.connect();
                        if (jackHenry.xperience.messageBus.isConnected) {
                            jackHenry.xperience.messageBus.subscribe('AppCls', this.onAppCls);
                            resolve();
                        } else {
                            reject();
                        }
                    } else {
                        this.sessionStorageService.set('xperienceHosted', false);
                        resolve();
                    }
                });
            }, 1000);
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private onAppCls(messageContext: any) {
        const { jackHenry } = window;
        const response = new jackHenry.enterprise.businessObjects.AppClsResponse();
        response.ClsStat = 'ClsAck';
        response.RsStat = 'Success';

        messageContext.reply(response);
        jackHenry.xperience.messageBus.disconnect(true);
        window.close();
    }
}
