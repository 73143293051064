import { clone } from '@jack-henry/frontend-utils/functions';
import { AccountModelDto } from '@treasury/api/bo';
import { DomainEntity } from '../../../../shared';

const nullAccount: AccountModelDto = {
    id: 0,
    number: '',
    type: '',
    isCompanyAccount: false,
};

export class Account extends DomainEntity<AccountModelDto> {
    constructor(dto = clone(nullAccount)) {
        super(dto);
    }

    public createDefault(): this {
        return new Account(clone(nullAccount)) as this;
    }

    public get accountName() {
        return this.dto.companyName;
    }

    public get accountNumber() {
        return this.dto.number;
    }

    public get cifNumber() {
        return this.dto.cifNumber;
    }

    public get accountType() {
        return this.dto.type;
    }
}
