import {
    activity,
    activityDate,
    activityKeyWord,
    // filters
    activityPeriod,
    activityType,
    backOfficeUsersMultiple,
    channelSessionInformation,
    companyUserNameMultiple,
    ipAddress,
    selectCompany,
    sessionInformation,
    timeSelect,
    userActivities,
    userType,
} from '@treasury/policy/backoffice-report.js';

import { loginId, userActivity, userName } from '@treasury/policy/user';

export const fiColumns = isUisEnabled => [
    {
        field: 'activityDate',
        label: 'Date/Time',
    },
    {
        field: 'userName',
        label: 'User Name',
    },
    {
        field: 'loginId',
        label: isUisEnabled ? 'TM User ID' : 'Login Id',
    },
    {
        field: 'activity',
        label: 'Activity',
    },
    {
        field: 'ipAddress',
        label: 'IP Address',
    },
    {
        field: 'sessionInformation',
        label: '',
        type: 'tooltip',
        sortable: false,
    },
];

export const channelColumns = isUisEnabled => [
    {
        field: 'activityDate',
        label: 'Date/Time',
    },
    {
        field: 'userName',
        label: 'User Name',
    },
    {
        field: 'loginId',
        label: isUisEnabled ? 'TM User ID' : 'Login Id',
    },
    {
        field: 'userType',
        label: 'User Type',
    },
    {
        field: 'activityType',
        label: 'Activity Type',
    },
    {
        field: 'activity',
        label: 'Activity',
    },
    {
        field: 'channelSessionInformation',
        label: '',
        type: 'tooltip',
        sortable: false,
    },
];

export const fiFields = {
    activityDate: activityDate.thatIs.readOnly(),
    userName: userName.thatIs.readOnly(),
    loginId: loginId.thatIs.readOnly(),
    userActivity: userActivity.thatIs.readOnly(),
    activity: activity.thatIs.readOnly(),
    ipAddress: ipAddress.thatIs.readOnly(),
    sessionInformation: sessionInformation.thatIs.readOnly(),
};

export const channelFields = {
    activityDate: activityDate.thatIs.readOnly(),
    userName: userName.thatIs.readOnly(),
    loginId: loginId.thatIs.readOnly(),
    userType: userType.thatIs.readOnly(),
    activityType: activityType.thatIs.readOnly(),
    activity: activity.thatIs.readOnly(),
    channelSessionInformation: channelSessionInformation.thatIs.readOnly(),
};

const getFilterValue = (customValues, field, fallBack) => customValues[field] ?? fallBack;

const mapCustomValuesToDatePeriod = customValues => {
    if (!Object.keys(customValues).length) return null;
    if (!customValues.fromDate) return [customValues.specificDate];
    return [customValues.fromDate, customValues.toDate];
};

export const fiFilters = customValues => [
    {
        field: 'userIds',
        fieldType: backOfficeUsersMultiple,
        value: getFilterValue(customValues, 'userIds', []),
    },
    {
        field: 'activityPeriodDate',
        fieldType: activityPeriod,
        value: mapCustomValuesToDatePeriod(customValues),
    },
    {
        field: 'startTime',
        fieldType: timeSelect.with.label('Start Time'),
        value: getFilterValue(customValues, 'fromTime', ''),
    },
    {
        field: 'endTime',
        fieldType: timeSelect.with.label('End Time'),
        value: getFilterValue(customValues, 'toTime', ''),
    },
    {
        field: 'activityKeyword',
        fieldType: activityKeyWord,
        value: getFilterValue(customValues, 'activityKeyword', ''),
    },
];

export const channelFilters = customValues => [
    {
        field: 'companyId',
        fieldType: selectCompany,
        value: getFilterValue(customValues, 'companyId', ''),
    },
    {
        field: 'userIds',
        fieldType: companyUserNameMultiple,
        value: getFilterValue(customValues, 'userIds', []),
    },
    {
        field: 'userActivityTypeId',
        fieldType: userActivities,
        value: getFilterValue(customValues, 'userActivityTypeId', ''),
    },
    {
        field: 'activityPeriodDate',
        fieldType: activityPeriod,
        value: mapCustomValuesToDatePeriod(customValues),
    },
    {
        field: 'startTime',
        fieldType: timeSelect.with.label('Start Time'),
        value: getFilterValue(customValues, 'fromTime', ''),
    },
    {
        field: 'endTime',
        fieldType: timeSelect.with.label('End Time').thatHas.validator({
            name: 'end-time',
            validate: (value, model, record) => {
                if (!value) return true;
                const { interval } = record.getField('startTime');
                return value.interval > interval;
            },
        }),
        value: getFilterValue(customValues, 'toTime', ''),
    },
];

export const isChannelActivityReport = type => type.toLowerCase().includes('channel');

export const activityFilters = (type, customValues) =>
    isChannelActivityReport(type) ? channelFilters(customValues) : fiFilters(customValues);

export const activityFields = type => (isChannelActivityReport(type) ? channelFields : fiFields);

export const activityColumns = (type, isUisEnabled) =>
    isChannelActivityReport(type) ? channelColumns(isUisEnabled) : fiColumns(isUisEnabled);
