import { ReportRequests } from '@treasury/domain/backoffice/requests/reports/report-requests';
import { WireUploadReportServices } from '@treasury/domain/backoffice/requests/reports/wire-upload';
import { UsersService } from '@treasury/domain/backoffice/services/users/users-service.ts';
import { alphabetizeOnText } from '@treasury/domain/shared/utilities/compare-functions';
import { WireIsoService } from '@treasury/domain/wires';
import { Recordset } from '@treasury/FDL';
import '@treasury/omega/layouts/omega-report';
import { companyName } from '@treasury/policy/company.js';
import { disableFutureDates } from '@treasury/policy/lib/utils/disable-date-functions';
import {
    amountRange,
    count,
    date,
    datePicker,
    id,
    list,
    money,
    number,
    string
} from '@treasury/policy/primitives';
import { name } from '@treasury/policy/user';
import { flattenObject, printNode } from '@treasury/utils';
import { css, html, LitElement, render } from 'lit';
import './wire-upload-wire-file-dialog.js';

class WireUploadContainer extends LitElement {
    static get properties() {
        return {
            actions: Object,
            alert: Object,
            columns: Array,
            filters: Array,
            fields: Object,
            files: Array,
            wireRecordset: Object,
            showWireFileDetails: Boolean,
            selectedWireFile: Object,
            subTitle: String,
            reportRecordset: Object,
            printing: Boolean,
            dialogTitle: String,
            wireIsoService: WireIsoService,
            wireIsoLabels: Object,
        };
    }

    constructor() {
        super();
        this.wireIsoService = new WireIsoService();
        this.subTitle = '';
        this.actions = {
            View: async record => {
                this.selectedWireFile = record.values;
                const wires = record.values.wireDetails.map(wire => flattenObject(wire));
                this.wireRecordset = new Recordset(this.wireFields, wires);
                this.dialogTitle = `${this.selectedWireFile?.fileName} (Source: ${this.selectedWireFile?.uploadedBy})`;
                await this.wireRecordset.requestUpdate();
                this.showWireFileDetails = true;
            },
        };
        /**
         * fields for the main table of the report
         */
        this.fields = {
            uploadDate: date.thatIs.readOnly(),
            fileName: string.thatIs.readOnly(),
            uploadedBy: string.thatIs.readOnly(),
            processedDate: string.thatIs.readOnly(),
            processedBy: string.thatIs.readOnly(),
            totalAmount: money.thatIs.readOnly(),
            countWires: count.thatIs.readOnly(),
            countBeneficiaries: count.thatIs.readOnly(),
            countApproved: count.thatIs.readOnly(),
            countPendingApproval: count.thatIs.readOnly(),
            countRejected: count.thatIs.readOnly(),
            countExpired: count.thatIs.readOnly(),
            id: id.thatIs.readOnly(),
            wireDetails: list.thatIs.readOnly(),
        };
        /**
         * fields for the wires table in the dialog (for a single file)
         */
        this.wireFields = {
            beneficiary_name: name.thatIs.readOnly(),
            debitAccount_type: string.thatIs.readOnly(),
            wireCompany_name: companyName.thatIs.readOnly(),
            effectiveDate: date.thatIs.readOnly(),
            amount: money.thatIs.readOnly(),
            purpose: string.thatIs.readOnly(),
            wireId: number.thatIs.readOnly(),
        };

        this.filters = [
            {
                field: 'companyIds',
                fieldType: string.with
                    .options({
                        data: ReportRequests.getCompanies(),
                        text: record => record.name,
                        value: record => record.id,
                        compareFunction: alphabetizeOnText,
                    })
                    .with.filtering()
                    .thatHas.label('Company Name')
                    .thatIs.required()
                    .with.placeholder('Select Company')
                    .as.tag('omega-select'),
                value: '',
                required: true,
            },
            {
                field: 'fileName',
                fieldType: string.thatIs
                    .disabledWhen(record => !this.isCompanySelected(record))
                    .and.validator({
                        name: 'fileNameLength',
                        validate: modelValue => modelValue.length < 100,
                    })
                    .thatHas.label('File Name')
                    .as.tag('omega-input'),
                value: '',
            },
            {
                field: 'userIds',
                // eslint-disable-next-line no-restricted-globals
                fieldType: name.with
                    .options({
                        fetch: async record => {
                            const companyId = record.getField('companyIds');
                            if (id) {
                                this.subTitle = '';
                                this.pageTitle = 'Wire File Upload Report';
                                const cName = await ReportRequests.getCompanyInfo(companyId);
                                this.pageTitle = cName?.name;
                                this.subTitle = 'Wire File Upload Report';
                            }
                            return companyId
                                ? UsersService.getUsersByCompanyIds(companyId)
                                : Promise.resolve([]);
                        },
                        text: record => {
                            if (record.userId === 'System') {
                                return record.userId;
                            }
                            return `${record.userName} - ${record.userId}`;
                        },
                        value: record => record.id,
                    })
                    .with.filtering()
                    .and.multipleValues()
                    .thatIs.disabledWhen(record => !this.isCompanySelected(record))
                    .thatHas.label('Source')
                    .thatHas.placeholder('')
                    .as.tag('omega-select'),
                value: [],
            },
            {
                field: 'amount',
                fieldType: amountRange.with
                    .schema('range')
                    .thatIs.disabledWhen(record => !this.isCompanySelected(record))
                    .thatHas.label('Amount')
                    .as.tag('omega-range'),
                value: ['specific', 0, 0],
            },
            {
                field: 'uploadDate',
                fieldType: datePicker.with
                    .range()
                    .thatIs.disabledWhen(record => !this.isCompanySelected(record))
                    .thatHas.label('Received Date')
                    .thatHas.selectionDisabledFunctions(disableFutureDates)
                    .as.tag('omega-datepicker'),
                value: '',
            },
        ];
        
        this.showWireFileDetails = false;
        this.pageTitle = 'Wire File Upload Report';
        this.rowsPerPage = 25;
        this.reportRecordset = new Recordset(
            this.fields,
            WireUploadReportServices.fetchWireUploads
        );
        this.reportRecordset.setInitialPageSize(this.rowsPerPage);
    }

    async firstUpdated() {
        this.wireIsoLabels = await this.wireIsoService.getLabels();

        this.columns = [
            {
                field: 'fileName',
                label: 'File Name',
            },
            {
                field: 'uploadDate',
                label: 'Received Date',
            },
            {
                field: 'uploadedBy',
                label: 'Source',
            },
            // TODO: Uncomment these in Phase 2
            // {
            //     field: 'processedDate',
            //     label: 'Processed Date',
            // },
            // {
            //     field: 'processedBy',
            //     label: 'Processed By',
            // },
            {
                field: 'totalAmount',
                label: 'Total Amount',
            },
            {
                field: 'countWires',
                label: 'Total Wires',
            },
            {
                field: 'countBeneficiaries',
                label: `Total ${this.wireIsoLabels.creditors}`
            },
            {
                field: 'countPendingApproval',
                label: 'Pending Approval',
            },
            {
                field: 'countApproved',
                label: 'Approved',
            },
            {
                field: 'countRejected',
                label: 'Rejected',
            },
            {
                field: 'countExpired',
                label: 'Expired',
            },
            {
                type: 'actions',
                label: '',
                actions: [
                    {
                        label: 'View',
                        action: 'View',
                        visibleWhen: record => record.values.wireDetails?.length > 0,
                    },
                ],
            },
        ];
        this.wireColumns = [
            {
                field: 'beneficiary_name',
                label: this.wireIsoLabels.creditor,
            },
            {
                field: 'debitAccount_type',
                label: 'Debit Account',
            },
            {
                field: 'wireCompany_name',
                label: 'Wire Company Name',
            },
            {
                field: 'effectiveDate',
                label: 'Effective Date',
            },
            {
                field: 'amount',
                label: 'Amount',
            },
        ];
    }

    async clearSubtitle() {
        this.subTitle = '';
        this.pageTitle = 'Wire File Upload Report';
    }

    isCompanySelected(record) {
        return record.getField('companyIds');
    }

    async printReport() {
        this.printing = true;
        const printRecordset = new Recordset(this.fields, () => this.reportRecordset.getData());
        await printRecordset.requestUpdate();
        printRecordset.setInitialPageSize(printRecordset.filteredCount);
        this.printing = false;
        const printableDiv = document.createElement('div');
        const printableTable = html`<omega-table
            .recordset=${printRecordset}
            .columnDefinitions=${this.columns}
        ></omega-table>`;
        render(printableTable, printableDiv);
        return printNode(this.pageTitle, printableDiv);
    }

    renderWireFileDetailsDialog() {
        return html`<wire-upload-wire-file-dialog
            .wireFile=${this.selectedWireFile}
            .wireRecordset=${this.wireRecordset}
            .wireColumns=${this.wireColumns}
            .wireFields=${this.wireFields}
            .dialogTitle=${this.dialogTitle}
            .open=${this.showWireFileDetails}
            @close=${() => {
                this.showWireFileDetails = false;
                this.selectedWireFile = null;
                this.dialogTitle = null;
            }}
        ></wire-upload-wire-file-dialog>`;
    }

    render() {
        return html`
            <omega-report
                .title=${this.pageTitle}
                .subTitle=${this.subTitle}
                .actions=${this.actions}
                .recordset=${this.reportRecordset}
                .filters=${this.filters}
                .columns=${this.columns}
                .options=${['print']}
                .printFunction=${() => this.printReport()}
                .rowsPerPage=${this.rowsPerPage}
                @resetFilter=${this.clearSubtitle}
            ></omega-report>
            ${this.renderWireFileDetailsDialog()}
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                --omega-table-background: var(--omega-white);
            }
        `;
    }
}

customElements.define('wire-upload-container', WireUploadContainer);
export default WireUploadContainer;
